import { AnchorLink } from "gatsby-plugin-anchor-links";
import * as React from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import Layout from "../containers/layout";

const NotFoundPage: React.FC = () => {
    return (
        <Layout>
            <SEO title="Side ikke funnet!" description={"Side ikke funnet!"} keywords={["404"]} />
            <PageContainer>
                <PageTitle>Oops! 404.</PageTitle>
                <PageText>Siden du prøver å finne eksisterer ikke.</PageText>
                <HomeButton to={`/`} title={`Tilbake til forsiden`} />
            </PageContainer>
        </Layout>
    );
};

export default NotFoundPage;

const PageContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;

    max-width: 960px;
    padding: 5rem 3rem 1rem;
    margin: 0 auto;
`;

const PageTitle = styled.h2`
    font-size: var(--font-title2-size);
    margin: 2rem 0;
    text-align: center;
`;

const PageText = styled.p`
    font-size: var(--font-base-size);
    margin: 1rem 0;
    text-align: center;
`;

const HomeButton = styled(AnchorLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-large-size);
    margin: 2rem 0;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 2px;
    height: 3rem;
    padding: 0 1rem;
    transition: var(--transition);

    &:hover {
        background-color: var(--button-color-hover);
        cursor: pointer;
    }
`;
